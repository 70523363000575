// FontAwesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

// JQuery UI
@import "~jquery-ui/themes/base/core";
@import "~jquery-ui/themes/base/theme";
@import "~jquery-ui/themes/base/dialog";

// Jalis Theme
@import "../../vendor/stylesheets/jalis/style.scss";
@import "../../vendor/stylesheets/jalis/dev.scss";

// JQuery Fancybox
@import "~@fancyapps/ui/dist/fancybox/fancybox";

// Import fonts
@font-face {
  font-family: "Gotham Light";
  src: url("../../vendor/fonts/Gotham Light.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Medium";
  src: url("../../vendor/fonts/Gotham Medium.otf") format("opentype");
}

form.stack-form select,
form.stack-form textarea,
form.stack-form button,
form.stack-form input[type="text"],
form.stack-form input[type="tel"],
form.stack-form input[type="submit"],
form.stack-form input[type="password"],
form.stack-form input[type="email"] {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #ffffff none repeat scroll 0 0;
  border-color: -moz-use-text-color #0099cc #0099cc -moz-use-text-color;
  border-image: none;
  border-radius: 0;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  color: #39abd7;
  font-family: "proxima-nova",sans-serif;
  margin: 0;
  padding: 9px;
  height: auto;
}

form.stack-form .invalid-feedback {
  color: #fff;
  padding-top: 5px;
  font-weight: bold;
  margin-bottom: 18px;
}

form.stack-form .form-check {
  display: block;
  height: 65px;
  margin-top: 20px;
  margin-bottom: 10px;
}

form.stack-form .form-check label {
  color: white;
  padding-left: 10px;
  text-align: justify;
  display: block;
  top: -20px;
  position: relative;
  left: 10px;
  font-family: "Gotham Light";
  font-size: 16px;
  width: 90%;
}

form.stack-form .form-check label.font-weight-bold {
  font-weight: 700 !important;
}

form.stack-form .bts--centre {
  margin-top: 10px;
}

form.stack-form input[type="submit"] {
  width: 130px;
  font-size: 17px;
  padding: 18px 27px;
  margin: 25px 0 0;
}

form.stack-form .form-group {
  margin-bottom: 1px;
}

.form-control::-webkit-input-placeholder { color: #39abd7; }
.form-control:-moz-placeholder { color: #39abd7; }
.form-control::-moz-placeholder { color: #39abd7; }
.form-control:-ms-input-placeholder { color: #39abd7; }

#moteurRecherche .diviseurRh.with_model {
  width: 20%;
}

.privacy p {
  margin: 1em 0;
}

#modal-holder {
  padding: 5px;
}

.booklet-form {
  background: #39abd7;
}

.booklet-form .title {
  padding: 10px;
}

.booklet-form button {
  width: 100%;
}

.booklet-form h1,
.booklet-form h2 {
  color: #fff;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 19px;
}

.booklet-form .h1 {
  font-family: "Gotham Light";
  color: #fff;
  margin: 0px;
  margin-top: 10px;
  text-align: center;
  font-size: 19px;
}

.booklet-form .h2 {
  font-family: "Gotham Medium";
  color: #fff;
  margin: 0px;
  text-align: center;
  font-size: 19px;
}

.booklet-form a {
  font-family: "Gotham Medium";
  color: #fff;
  margin: 0px;
  text-align: center;
  font-size: 17px;
}

.booklet-form h1 {
  font-family: "Gotham Light";
}

.booklet-form h2 {
  font-family: "Gotham Medium";
}

.booklet-form-inner {
  padding: 10px;
  padding-bottom: 15px;
}

.booklet-form-outer {

}

.container {
  width: 460px;
  height: 160px;
  margin: auto;
  padding: 5px;
}

.one {
  width: 30%;
  height: 160px;
  float: left;
}

.two {
  width: 70%;
  height: 160px;
  margin-left: 33%;
}

.two li {
  position: relative;
  display: block;
  padding: 9px 0;
  margin: 0;
  border-top: 1px solid hsla(0,0%,100%,.05);
  border-bottom: 1px solid rgba(0,0,0,.05);
}
